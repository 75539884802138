import axios from "axios";
import { refreshSessionOrLogout } from "./interceptors";
import tokenManager from "./tokenManager";
import { commonHeaders } from "./util";

const setupClient = axios.create({
    baseURL:
        //"http://localhost:60000/api/v1/", //Local address
        "https://propensity4setup-test.samplecupboard.com/api/v1/",
    headers: commonHeaders,
});

setupClient.interceptors.request.use(
    (config) => {
        const token = tokenManager.getLocalAccessToken();
        if (token) {
            config.headers["Authorization"] = "Bearer " + token;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);


setupClient.interceptors.response.use(
    (res) => {
        return res;
    },
    refreshSessionOrLogout(setupClient)
);

export default setupClient;
