import { makeStyles } from "@material-ui/core";

export const muiInputStyle = makeStyles((theme) => ({
  formControl: {
    marginBottom: 10,
  },
  selectEmpty: {},
  label: {
    backgroundColor: "white",
  },
  dialogRoot: {
    overflowY: "unset",
  },
  dialogPaper: {
    overflowY: "visible",
  },
  dialogContent: {
    overflowY: "visible",
    width: "100%",
  },
}));

export const pwdInputStyle = {
  height: "48px",
  marginTop: "16px",
  marginBottom: "8px",
};
