import { createMuiTheme } from "@material-ui/core";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#6C7CD9",
      contrastText: "#fff",
    },
  },
  overrides: {
    MuiButton: {
      root: {
        fontWeight: 400,
        textTransform: "capitalize",
      },
    },
    MuiTypography: {
      h1: {
        color: "#000",
        fontWeight: 300,
        marginBottom: 4,
        fontSize: 34,
      },
      subtitle1: {
        color: "#707070",
        fontWeight: 500,
        fontSize: 15,
      },
    },
  },
});

export { theme };
