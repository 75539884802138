import { useCallback, useEffect, useMemo, useState } from "react";
import { useCurrentProject } from "../contexts/CurrentProjectContext";
import setupService from "../services/setup";

export function useProjectList(companyId, { completed = false } = {}) {
  
  const [isLoading, setIsLoading] = useState(false);
  const [projects, setProjects] = useState([]);

  const fetchProjects = useCallback(async () => {
    if (!companyId) return;
    try {
      setIsLoading(true);
      let data = await setupService.fetchProjects(companyId, completed);
      
      let transform = (d) => ({
        name: d?.projectName,
        id: d?.id,
        companyName: d?.companyName,
        therapeuticName: d.therapeuticName,
        createdAt: d?.createdAt,
      });

      data = data
        .map(transform)
        // sort by date created
        .sort((p1, p2) => new Date(p2.createdAt) - new Date(p1.createdAt));

      setProjects(data);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setProjects([]);
    }
  }, [companyId, completed]);

  useEffect(() => {
    fetchProjects();
  }, [fetchProjects]);

  return useMemo(
    () => [isLoading, projects, fetchProjects, setProjects],
    [fetchProjects, isLoading, projects]
  );
}


export function useCurrentCompanyProjectList(opts) {
  const { company } = useCurrentProject();
  return useProjectList(company.id, opts)
}