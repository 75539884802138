import { createContext, useState, useEffect } from "react"

const AuthContext = createContext();

function AuthContextProvider({children}) {
    const [user, setUser] = useState(() => {
       return JSON.parse(sessionStorage.getItem('authUser') || null )
    })

    useEffect(() => {
        localStorage.getItem('user')    
    }, [])

    return (
        <AuthContext.Provider value={{ user, setUser }}>
            {children}
        </AuthContext.Provider>
    )
}

export {AuthContextProvider, AuthContext }