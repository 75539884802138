import TokenService from "./tokenManager";

let retryTimes = 0;

export function refreshSessionOrLogout(instance) {
  return async (err) => {
    const { config } = err;

    if (
      config.url !== "/User/login" &&
      config.url !== "User/refresh" &&
      err.response
    ) {
        
      // Access Token was expired, refresh session or bailout
      if (err.response.status === 401 && retryTimes < 1) {
        retryTimes++;
        try {
          const rs = await instance.post("User/refresh", {
            refreshToken: TokenService.getLocalRefreshToken(),
          });

          const { accessToken } = rs.data;
          TokenService.updateLocalAccessToken(accessToken);

          return instance(config);
        } catch (error) {
          TokenService.removeUser();
          window.location.assign("/login");
        }
      }
    }

    return Promise.reject(err);
  };
}
