import * as React from "react";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Box } from "@material-ui/core";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AppDialog = ({
  open,
  onClose,
  withDismissButton,
  children,
  ...props
}) => {
  return (
    <Dialog
      {...props}
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      {withDismissButton && (
        <Box
          position="absolute"
          top={0}
          right={0}
          display="flex"
          justifyContent="flex-end"
          pt={1}
          pr={2}
        >
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      )}
      {children}
    </Dialog>
  );
};

export default AppDialog;
