import React from "react";
import { styled } from "../stitches.config";
import { ReactComponent as PersonIcon } from "../assets/icons/person.svg";
import { ReactComponent as PlusIcon } from "../assets/icons/plus.svg";
import { ReactComponent as Logo } from "../assets/icons/logo.svg";
import KeyboardArrowDownOutlinedIcon from "@material-ui/icons/KeyboardArrowDownOutlined";
import { DropdownRoot } from "../components/Dropdown";
import Box from "./Box";
import {
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Button,
} from "@material-ui/core";
import { useHistory } from "react-router";
import { useCurrentProject } from "../contexts/CurrentProjectContext";
import { useLocation } from "react-router-dom";
import styledComponents from "styled-components";
import { useContext } from "react";
import { AuthContext } from "../AuthContextProvider";

const Seperator = styled("div", {
  height: "30px",
  width: "1px",
  background: "$gray100",
});

const StyledNav = styled("nav", {
  gridArea: "nav",
  bg: "white",
  boxShadow: "2px 0px 4px rgba(0,0,0, 0.2)",
  px: "24px",
  py: "14px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  position: "relative",
  zIndex: 10,
});

const UserMenu = styled("div", {
  display: "flex",
  alignItems: "center",
  cursor: "pointer",

  ".user-avatar": {
    circle: 40, // 40 diameter
    display: "flex",
    alignItems: "center",
    background: "$gray50",
    justifyContent: "center",
    marginRight: 8,
  },

  ".user-name": {
    textTransform: "capitalize",
    fontSize: 14,
  },
});

const ProjectDetails = styledComponents.div`
    display: flex;
    align-items: center;

    .project-details-item {
      padding: 0 40px;
      max-width: 200px;
      
      label {
        color: #999;
        font-size: 14px;
        
      }

      div {
        font-size: 15px;
        color: #222;
        text-transform: capitalize;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;      
      }
    }
  }
`;

export default function Nav() {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const history = useHistory();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const logout = () => {
    history.push("/");
    sessionStorage.removeItem("authUser");
  };

  // lets show the the dropdowns when a company is selected;
  const { company, project, therapeuticArea } = useCurrentProject();

  const location = useLocation();

  // show dropdowns
  const hideHeaderUi = !company || location.pathname === "/analyst/setup/new";

  const { user } = useContext(AuthContext);

  return (
    <>
      <StyledNav>
        <Box css={{}}>
          <Logo />
        </Box>
        <Box css={{ display: "flex", alignItems: "center", spacingX: 14 }}>
          {!hideHeaderUi && (
            <ProjectDetails>
              {company && (
                <>
                  <div className="project-details-item">
                    <label>Company</label>
                    <div>{company.name}</div>
                  </div>
                  <Seperator />
                </>
              )}

              {project && (
                <>
                  <div className="project-details-item">
                    <label>Project</label>
                    <div title={project.name}>{project?.name}</div>
                  </div>
                  <Seperator />
                  <div className="project-details-item">
                    <label>Therapeutic area</label>
                    <div>{therapeuticArea?.name}</div>
                  </div>
                  <Seperator />
                </>
              )}
            </ProjectDetails>
          )}
          <Button
            variant="outlined"
            color="primary"
            onClick={() => history.push("/analyst/setup/new")}
            style={{ minWidth: "190px" }}
          >
            <Box style={{ display: "flex", alignItems: "center" }}>
              <Box style={{ marginRight: 5 }}>Add new project</Box>
              <PlusIcon />
            </Box>
          </Button>
          <DropdownRoot>
            <UserMenu
              style={{ marginRight: "0", marginLeft: ".5rem", flexShrink: 0 }}
              ref={anchorRef}
              aria-controls={open ? "menu-list-grow" : undefined}
              aria-haspopup="true"
              onClick={handleToggle}
            >
              <div className="user-avatar">
                <PersonIcon />
              </div>
              <div className="user-name">
                {user?.firstName + " " + user?.lastName}
              </div>
            </UserMenu>

            <KeyboardArrowDownOutlinedIcon
              ref={anchorRef}
              aria-controls={open ? "menu-list-grow" : undefined}
              aria-haspopup="true"
              onClick={handleToggle}
              fontSize="small"
              color="primary"
            />

            <Popper
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              style={{
                zIndex: 10,
              }}
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList
                        autoFocusItem={open}
                        id="menu-list-grow"
                        onKeyDown={handleListKeyDown}
                      >
                        {user.isAdmin && (
                          <MenuItem onClick={() => history.push("/admin")}>
                            Switch to admin
                          </MenuItem>
                        )}
                        <MenuItem onClick={logout}>Logout</MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </DropdownRoot>
        </Box>
      </StyledNav>
    </>
  );
}
