import { Route, Switch } from "react-router-dom";
import "./globalStyles";
import AnalystLayout from "./Layouts/AnalystLayout";
import AuthRoute from "./components/AuthRoute";
import { AuthContext } from "./AuthContextProvider";
import { lazy, useContext } from "react";

const Login = lazy(() => import("./Pages/Login"));
const Signup = lazy(() => import("./Pages/Signup"));
const ResetPassword = lazy(() => import("./Pages/ResetPassword"));
const Admin = lazy(() => import("./Pages/Admin"));

// app routes

export function Routes() {
  const { user } = useContext(AuthContext);
  return (
    <Switch>
      <Route exact path={["/", "/login"]} component={Login}></Route>
      <Route exact path="/signup" component={Signup} />
      <Route exact path="/password-reset" component={ResetPassword} />
      {user && user.isAdmin ? <AuthRoute path="/admin" component={Admin} /> : null}
      <AuthRoute path="/analyst" component={AnalystLayout}></AuthRoute>
    </Switch>
  );
}
