import { useEffect, useState } from "react";
import setupService from "../services/setup";

export function useCompaniesList() {
  const [isLoading, setIsLoading] = useState(false);
  const [companies, setCompanies] = useState([]);

  const fetchCompanies = async () => {
    try {
      setIsLoading(true);
      await setupService.fetchCompanies().then((data) => {
        setCompanies(data);
      });
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const loadCompanies = () => {
    fetchCompanies();
  };

  useEffect(() => {
    fetchCompanies();
  }, []);

  return [isLoading, companies, loadCompanies];
}
