import { createContext, useContext, useEffect, useMemo, useState } from "react";
import createPersistedState from "use-persisted-state";

const CurrentProjectContext = createContext(null);

const useProjectState = createPersistedState("project", sessionStorage);
const useCompanyState = createPersistedState("company", sessionStorage);

export function CurrentProjectProvider(props) {
  const [project, setProject] = useProjectState(null);
  const [company, setCompany] = useCompanyState(null);
  const [therapeuticArea, setTherapeuticArea] = useState(null);

  useEffect(() => {
    // change the therapeutic area when a new project is selected
    if (project) {
      let t = project.therapeuticName?.toLowerCase();
      setTherapeuticArea({
        name: t,
        value: t,
      });
    }
  }, [project]);

  let value = useMemo(
    () => ({
      project,
      setProject,
      company,
      setCompany,
      therapeuticArea,
      setTherapeuticArea,
    }),
    [project, setProject, company, setCompany, therapeuticArea, setTherapeuticArea]
  );

  return <CurrentProjectContext.Provider value={value} {...props} />;
}

export function useCurrentProject() {
  const value = useContext(CurrentProjectContext);
  if (!value) {
    throw new Error(
      `useCurrentProject must be used within a <CurrentProjectProvider />`
    );
  }
  return value;
}
