import { createStyled } from "@stitches/react";

export const { styled, css } = createStyled({
  tokens: {
    colors: {
      $white: "#fff",
      $gray10: "#F5F5F5",
      $gray50: "rgba(233, 233, 233, 0.5)",
      $gray100: "#E5E5E5",
      $blue50: "rgba(108, 124, 217, .5)",
      $blue100: "#6C7CD9",
    },
    space: {
      $1: "4px",
      $2: "8px",
      $3: "12px",
      $4: "16px",
      $full: "100%",
    },
    fonts: {
      $roboto: "Roboto, sans-serif",
    },
    fontSizes: {
      l: "26px",
    },
  },
  utils: {
    bg: (config) => (value) => ({
      backgroundColor: value,
    }),
    px: (config) => (value) => ({
      paddingLeft: value,
      paddingRight: value,
    }),
    py: (config) => (value) => ({
      paddingBottom: value,
      paddingTop: value,
    }),
    mx: (config) => (value) => ({
      marginLeft: value,
      marginRight: value,
    }),
    my: (config) => (value) => ({
      marginBottom: value,
      marginTop: value,
    }),
    circle: (config) => (diameter) => ({
      height: diameter,
      width: diameter,
      borderRadius: "999px",
    }),
    spacingX: (config) => (value) => ({
      "> *:not(:last-child)": {
        marginRight: value,
      },
    }),
    spacingY: (config) => (value) => ({
      "> *:not(:last-child)": {
        marginBottom: value,
      },
    }),
  },
});
