import React from "react";
import * as Yup from "yup";
import Select from "react-select";
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  FormLabel,
} from "@material-ui/core";
import Dialog from "../Dialog";

import { useFormik } from "formik";
import { useHistory } from "react-router";
import { useProjectList } from "../../hooks/useProjectList";
import { useCurrentProject } from "../../contexts/CurrentProjectContext";
import { useCompaniesList } from "../../hooks/useCompanies";
import { formLabelStyle } from "../../utils/select2Styles";

const NewProjectvalidationSchema = Yup.object({
  project: Yup.object().nullable().required("This field is required."),
  company: Yup.object().nullable().required("This field is required."),
});

const SelectProjectModal = React.memo(function ({
  showProjectSelect,
  setShowProjectSelect,
}) {
  const appState = useCurrentProject();

  const formik = useFormik({
    initialValues: {
      project: null,
      company: appState.company,
    },
    validationSchema: NewProjectvalidationSchema,
    onSubmit: async ({ project, company }) => {
      appState.setProject(project);
      appState.setCompany(company);
    },
  });

  const [isLoadingCompanies, companies] = useCompaniesList();
  const [isLoadingProjects, projects] = useProjectList(
    formik.values.company?.id
  );

  const history = useHistory();

  return (
    <form onSubmit={formik.handleSubmit}>
      <Dialog
        open={showProjectSelect}
        onClose={() => {
          setShowProjectSelect(false);
          history.push("/analyst");
        }}
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
        PaperProps={{ style: { overflowY: "visible" } }}
      >
        <DialogTitle id="form-dialog-title">
          Please select a project
        </DialogTitle>
        <DialogContent style={{ overflow: "visible" }}>
          <FormControl
            margin="normal"
            fullWidth
            error={formik.errors.company && formik.touched.company}
            required
          >
            <FormLabel style={formLabelStyle.label}>Company</FormLabel>
            <Select
              value={formik.values.company}
              onChange={(val) => {
                formik.setFieldValue("company", val);
              }}
              placeholder="Select company"
              name="company"
              options={companies}
              className="basic-multi-select"
              classNamePrefix="select"
              isLoading={isLoadingCompanies}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              menuShouldScrollIntoView
            />
            {formik.errors.company && formik.touched.company ? (
              <FormHelperText error>{formik.errors.company}</FormHelperText>
            ) : null}
          </FormControl>

          <FormControl
            margin="normal"
            fullWidth
            error={formik.errors.project && formik.touched.project}
            disabled={!formik.values.company}
            required
          >
            <FormLabel style={formLabelStyle.label}>Project</FormLabel>
            <Select
              value={formik.values.project}
              onChange={(val) => {
                formik.setFieldValue("project", val);
              }}
              isDisabled={!formik.values.company}
              placeholder="Select project"
              name="project"
              options={projects}
              className="basic-multi-select"
              classNamePrefix="select"
              isLoading={isLoadingProjects}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              menuShouldScrollIntoView
            />
            {formik.errors.project && formik.touched.project ? (
              <FormHelperText error>{formik.errors.project}</FormHelperText>
            ) : null}
          </FormControl>
        </DialogContent>
        <DialogActions style={{ padding: "16px 24px" }}>
          <Button
            onClick={() => {
              setShowProjectSelect(false);
              history.push("/analyst");
            }}
            color="secondary"
            variant="outlined"
            style={{ minWidth: "12rem" }}
            size="small"
          >
            Cancel
          </Button>
          <Button
            onClick={() => formik.handleSubmit()}
            disabled={formik.isSubmitting}
            color="primary"
            variant="contained"
            size="small"
            style={{ minWidth: "12rem" }}
          >
            {formik.isSubmitting ? (
              <CircularProgress size={20} color="primary" />
            ) : (
              "Continue "
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
});

export default SelectProjectModal;
