import React, { useState } from "react";
import * as Yup from "yup";
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  TextField,
} from "@material-ui/core";
import Dialog from "../Dialog";

import { useFormik } from "formik";
import { muiInputStyle, pwdInputStyle } from "../../utils/muiStyle";
import { PasswordField } from "../PasswordField";
import authService from "../../services/auth";
import { passwordCheck } from "../../utils/passwordCheck";

const NewProjectvalidationSchema = Yup.object({
  oldPassword: Yup.string().required("Old password is required."),
  password: Yup.string()
    .required("password is required.")
    .matches(
      passwordCheck,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    ),
  email: Yup.string().required("This field is required."),
});

const UpdatePasswordDialog = React.memo(function ({
  showPasswordUpdate,
  setShowPasswordUpdate,
  authUser,
}) {
  const classes = muiInputStyle();
  const [updateErr, setUpdateErr] = useState();

  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      password: "",
      email: authUser?.email,
    },
    validationSchema: NewProjectvalidationSchema,
    onSubmit: async () => {
      formik.setSubmitting(true);
      setUpdateErr();
      try {
        const response = await authService.updatePassword(formik.values);
        if ((response.status = 200)) {
          //update local storage
          sessionStorage.setItem(
            "authUser",
            JSON.stringify({
              ...authUser,
              hasChangedPassword: true,
            })
          );
          setShowPasswordUpdate(false);
        }
      } catch (error) {
        setUpdateErr(error);
        formik.setSubmitting(false);
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Dialog
        open={showPasswordUpdate}
        onClose={() => {
          setShowPasswordUpdate(false);
        }}
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
        PaperProps={{ style: { overflowY: "visible" } }}
      >
        <DialogTitle id="form-dialog-title">Update Password</DialogTitle>
        <DialogContent style={{ overflow: "visible" }}>
          <DialogContentText>
            You are using a default password. Please update it to continue.
          </DialogContentText>
          <PasswordField
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            error={formik.touched.oldPassword && formik.errors.oldPassword}
            inputStyle={pwdInputStyle}
            value={formik.values.oldPassword}
            name="oldPassword"
            placeholder="Old Password"
          />

          <PasswordField
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            error={formik.touched.password && formik.errors.password}
            inputStyle={pwdInputStyle}
            value={formik.values.password}
            name="password"
            placeholder="New Password"
          />

          <FormControl
            margin="normal"
            fullWidth
            error={formik.errors.email && formik.touched.email}
            disabled
          >
            <TextField
              size="small"
              variant="outlined"
              id="email"
              label="Email"
              className={classes.formControl}
              fullWidth
              required
              disabled
              error={formik.errors.email && formik.touched.email}
              value={formik.values.email}
              onChange={(e) => formik.setFieldValue("email", e.target.value)}
              helperText={
                formik.errors.email &&
                formik.touched.email &&
                formik.errors.email
              }
              InputLabelProps={{
                classes: {
                  root: classes.label,
                },
              }}
            />
          </FormControl>

          <Box>
            <ul>
              {updateErr && (
                <li className="text-danger list-square">{updateErr}</li>
              )}
              {formik.errors.email && formik.errors.email && (
                <li className="text-danger list-square">
                  {formik.errors.email}
                </li>
              )}
              {formik.errors.oldPassword && formik.touched.oldPassword && (
                <li className="text-danger list-square">
                  {formik.errors.oldPassword}
                </li>
              )}
              {formik.errors.password && formik.errors.password && (
                <li className="text-danger list-square">
                  {formik.errors.password}
                </li>
              )}
            </ul>
          </Box>
        </DialogContent>
        <DialogActions style={{ padding: "16px 24px" }}>
          <Button
            onClick={() => formik.handleSubmit()}
            disabled={formik.isSubmitting}
            color="primary"
            variant="contained"
            size="medium"
            style={{ minWidth: "12rem" }}
          >
            {formik.isSubmitting ? (
              <CircularProgress size={20} color="primary" />
            ) : (
              "Continue "
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
});

export default UpdatePasswordDialog;
