import { NoEncryption } from "@material-ui/icons";

export const customStyles = {
  singleValue: (provided) => ({
    ...provided,
    color: "#6C7CD9",
  }),
  control: (provided) => ({
    ...provided,
    color: "#6C7CD9",
    border: "none",
  }),
  container: (provided) => ({
    ...provided,
    width: "100%",
    minWidth: "190px",
  }),
};

export const signupStyles = {
  singleValue: (provided) => ({
    ...provided,
    color: "#6C7CD9",
    height: "auto",
    top: "22px",
  }),
  control: (provided) => ({
    ...provided,
    color: "#6C7CD9",
    height: "46px",
  }),
  container: (provided) => ({
    ...provided,
    width: "100%",
    border: "1px solid #707070",
    boxSizing: "border-box",
    borderRadius: "6px",
    // height: "46px"
  }),
  input: (provided) => ({
    ...provided,
    height: "auto",
    margin: "0",
    padding: "0",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    height: "46px",
  }),
};

export const formLabelStyle = {
  label: {
    marginBottom: 4,
  },
};
