import {  Suspense } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core";
import "./globalStyles";
import { Toaster } from "react-hot-toast";
import { theme } from "./theme";
import { QueryClient, QueryClientProvider } from "react-query";

import { CurrentProjectProvider } from "./contexts/CurrentProjectContext";
import ErrorBoundary from "./components/ErrorBoundary";
import { AuthContextProvider } from "./AuthContextProvider";
import { Routes } from "./Routes";


const queryClient = new QueryClient();

function App() {
  return (
    <ErrorBoundary>
      <AuthContextProvider>
        <ThemeProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <CurrentProjectProvider>
              <Suspense fallback={null}>
                <Router>
                  <Routes />
                </Router>
              </Suspense>
            </CurrentProjectProvider>
          </QueryClientProvider>
          <Toaster position="bottom-center" reverseOrder={false} />
        </ThemeProvider>
      </AuthContextProvider>
    </ErrorBoundary>
  );
}

export default App;
