import axios from "axios";
import { refreshSessionOrLogout } from "./interceptors";
import TokenService from "./tokenManager";
import { commonHeaders } from "./util";

const authClient = axios.create({
  baseURL:
    //"http://localhost:56022/api",
      "https://propensity4adminapi-test.samplecupboard.com/api",
  headers: commonHeaders,
});

authClient.interceptors.request.use(
  (config) => {
    const token = TokenService.getLocalAccessToken();
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);


authClient.interceptors.response.use(
  (res) => {
    return res;
  },
  refreshSessionOrLogout(authClient)

);

export default authClient;
