import { lazy, Suspense, useState, useEffect } from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { styled } from "../stitches.config";
import SideMenu from "../components/SideMenu";
import Nav from "../components/Nav";
import Text from "../components/Text";
import ProtectedRoute from "../components/ProtectedRoute";
import UpdatePasswordDialog from "../components/PasswordUpdate";
import { IdleTimeOut } from "../components/IdleTimeOut";

// lazy load pages
const Dashboard = lazy(() => import("../Pages/Dashboard"));
const NewSetup = lazy(() => import("../Pages/Setup/New"));
const Downloads = lazy(() => import("../Pages/Downloads"));
const Parameters = lazy(() => import("../Pages/Parameters"));
const ProjectSetup = lazy(() => import("../Pages/Setup/Project"));
const Analog = lazy(() => import("../Pages/Analog"));
const SizingScenario = lazy(() => import("../Pages/SizingScenario"));
const SizingOutcomes = lazy(() => import("../Pages/SizingOutcomes"));
const SizingCharts = lazy(() => import("../Pages/SizingCharts"));
const SizingReport = lazy(() => import("../Pages/SizingReport"));

const Container = styled("div", {
  display: "grid",
  gridTemplateColumns: "auto 1fr",
  gridTemplateRows: "90px 1fr",
  gridTemplateAreas: `"nav nav"
                    "sidemenu main"`,
  height: "100vh",
  bg: "#F2F2F2",
});

const Main = styled("main", {
  gridArea: "main",
  overflow: "auto",
  padding: "24px",
});

export default function AnalystLayout({ children }) {
  let authUser = JSON.parse(sessionStorage.getItem("authUser") || "{}");

  const { path } = useRouteMatch();
  const [showPwdUpdate, setShowPwdUpdate] = useState(false);

  useEffect(() => {
    if (!authUser.hasChangedPassword) {
      setShowPwdUpdate(true);
    }
  }, [authUser.hasChangedPassword]);

  //

  return (
    <IdleTimeOut>
      <Container>
        <SideMenu />
        <Nav />
        <Main>
          <Suspense fallback={<Text>Loading..</Text>}>
            <Switch>
              <Route path={path} component={Dashboard} exact />
              <Route path="/analyst/setup/new" exact component={NewSetup} />
              <ProtectedRoute path="/analyst/setup" component={ProjectSetup} />

              <ProtectedRoute
                path="/analyst/parameters"
                component={Parameters}
              />
              <Route path="/analyst/downloads" component={Downloads} />
              <ProtectedRoute path="/analyst/analogs" component={Analog} />
              <ProtectedRoute
                path="/analyst/sizing-scenario"
                component={SizingScenario}
                exact
              />
              <ProtectedRoute
                path="/analyst/sizing-outcomes"
                component={SizingOutcomes}
                exact
              />
              <ProtectedRoute
                path="/analyst/sizing-chart"
                component={SizingCharts}
              ></ProtectedRoute>
              <ProtectedRoute
                path="/analyst/reports"
                component={SizingReport}
              ></ProtectedRoute>
            </Switch>
          </Suspense>
          {children}
        </Main>
      </Container>
      {showPwdUpdate && (
        <UpdatePasswordDialog
          showPasswordUpdate={showPwdUpdate}
          setShowPasswordUpdate={setShowPwdUpdate}
          authUser={authUser}
        />
      )}
    </IdleTimeOut>
  );
}
