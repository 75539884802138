import { NavLink } from "react-router-dom";
import { styled } from "../stitches.config";
import Box from "./Box";
import { ReactComponent as AnalogIcon } from "../assets/icons/analog.svg";
import { ReactComponent as ParametersIcon } from "../assets/icons/parameters.svg";
import { ReactComponent as ReportsIcon } from "../assets/icons/reports.svg";
import { ReactComponent as SetupIcon } from "../assets/icons/setup.svg";
import { ReactComponent as SizingChartIcon } from "../assets/icons/sizing-chart.svg";
import { ReactComponent as SizingOutcomesIcon } from "../assets/icons/sizing-outcomes.svg";
import { ReactComponent as SizingScenarioIcon } from "../assets/icons/sizing-scenario.svg";
import { ReactComponent as DashboardIcon } from "../assets/icons/dashboard.svg";
import { ReactComponent as SettingsIcon } from "../assets/icons/settings-icon.svg";

const StyledMenu = styled("div", {
  gridArea: "sidemenu",
  bg: "white",
  boxShadow: "0px 2px 4px rgba(0,0,0, 0.2)",
  overflow: "auto",
});

const Item = styled("div", {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  borderBottom: "1px solid $gray50",
  padding: "$4",
  color: "black",
  position: "relative",
});

const MenuItem = styled(Item, {
  spacingY: 5,
  textDecoration: "none",
  "&:hover": {
    color: "$blue100",
    background: "#F1F3FF",
  },
  "&.active": {
    color: "$blue100",
    borderLeft: "5px solid $blue100",
  },
});

const MenuIcon = styled("svg", {
  width: 24,
  height: 24,
  "*": {
    fill: "currentcolor",
  },
});
const MenuLabel = styled("div", {
  fontSize: 14,
  fontWeight: "var(--fw, 400)",
  color: "currentcolor",
});

const menu = [
  { icon: DashboardIcon, label: "Dashboard", href: "/analyst", exact: true },
  { icon: SetupIcon, label: "Setup", href: "/analyst/setup", exact: false },
  { icon: ParametersIcon, label: "Parameters", href: "/analyst/parameters" },
  { icon: AnalogIcon, label: "Analog", href: "/analyst/analogs" },
  {
    icon: SizingScenarioIcon,
    label: "Sizing Scenario",
    href: "/analyst/sizing-scenario",
  },
  {
    icon: SizingOutcomesIcon,
    label: "Sizing Outcomes",
    href: "/analyst/sizing-outcomes",
  },
  {
    icon: SizingChartIcon,
    label: "Sizing Chart",
    href: "/analyst/sizing-chart",
  },
  { icon: ReportsIcon, label: "Reports", href: "/analyst/reports" },
];
export default function Sidemenu() {
  return (
    <>
      <StyledMenu>
        {/* <Item css={{ alignItems: "flex-start" }}>
        <DownloadTemplate />
      </Item> */}
        <Box css={{ position: "relative" }}>
          {menu.map((item, idx) => (
            <MenuItem
              key={idx}
              as={NavLink}
              activeClassName="active"
              to={item.href}
              exact={item.exact}
            >
              <MenuIcon as={item.icon}></MenuIcon>
              <MenuLabel>{item.label}</MenuLabel>
            </MenuItem>
          ))}
        </Box>
      </StyledMenu>
    </>
  );
}
