export const idleTimeout = 1000 * 5 * 60;

function sum(...args) {
  let total = 0;
  for (let i of args) {
    total += i;
  }
  return total;
}

export { sum };
