import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import React from "react";
import { useState, useContext, useCallback } from "react";
import AppDialog from "../Dialog";
import TokenService from "../../services/httpClient/tokenManager";
import { idleTimeout } from "../../essentials";
import IdleTimer from "react-idle-timer";
import { useRef } from "react";

const TimeoutCallbackContext = React.createContext(null);

export function IdleTimeOut({ children }) {
  const [showModal, setShowModal] = useState(false);
  const timeoutCallbackRef = useRef(null);

  const onIdle = (e) => {
    if (process.env.NODE_ENV !== "development") {
      setShowModal(true);
    }
  };

  const handleClose = useCallback(() => {
    setShowModal(false);
  }, []);

  const handleLogout = useCallback(async () => {
    let { current: timeoutCallback } = timeoutCallbackRef;
    if (typeof timeoutCallback === "function") {
      await Promise.resolve(timeoutCallback());
    }
    setTimeout(() => {
      sessionStorage.removeItem("authUser");
      TokenService.removeUser();
      window.location.reload();
    }, 1400);
  }, [timeoutCallbackRef]);

  return (
    <TimeoutCallbackContext.Provider value={timeoutCallbackRef}>
      <div>
        {children}
        <IdleTimer
          element={document}
          onIdle={onIdle}
          debounce={250}
          timeout={idleTimeout}
        />
        {showModal && (
          <IdleTimeOutModal
            showModal={showModal}
            handleClose={handleClose}
            handleLogout={handleLogout}
          />
        )}
      </div>
    </TimeoutCallbackContext.Provider>
  );
}

function useCountdown(seconds, onComplete){
  const [waitSec, setWaitSec] = useState(seconds)
  React.useEffect(() => {
    let timeout;
    if (waitSec <= 0) {
  
      if (typeof onComplete==="function") {
        onComplete();
      }
    } else {
      timeout = setTimeout(() => {
        setWaitSec((waitSec) => waitSec - 1);
      }, 1000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [onComplete, waitSec]);

  return waitSec;
  
}

// should work insha allah!
export const useOnSessionTimeout = (cb, deps = []) => {
  const timeoutCallbackRef = useContext(TimeoutCallbackContext);
  timeoutCallbackRef.current = cb;
};

export const IdleTimeOutModal = ({
  showModal,
  handleClose,
  handleLogout: onLogout,
}) => {
  
  const [cleaningUp, setCleaningUp] = useState(false);

  const handleLogout = useCallback(async () => {
    try {
      setCleaningUp(true);
      await Promise.resolve(onLogout());
    } catch (err) {
      // close if can't save current session
      handleClose();
    }
  }, [onLogout, handleClose]);


  const secondsLeft = useCountdown(5, handleLogout)

  return (
    <AppDialog
      maxWidth="sm"
      fullWidth
      open={showModal}
      onClose={(_, reason) => {
        if (reason === "backdropClick" && secondsLeft === 0) {
          // you are already late
          return;
        }
        handleClose();
      }}
      disableEscapeKeyDown={true}
    >
      <DialogTitle>You Have Been Idle!</DialogTitle>

      <DialogContent>
        {!cleaningUp ? (
          <p>You Will Get Timed Out in {secondsLeft} seconds. You want to stay?</p>
        ) : (
          <p>Cleaning up...</p>
        )}
      </DialogContent>

      <DialogActions>
        <Button
          disabled={cleaningUp}
          variant="outlined"
          color="secondary"
          onClick={handleLogout}
        >
          Logout
        </Button>
        <Button
          disabled={cleaningUp}
          variant="contained"
          color="primary"
          onClick={handleClose}
        >
          Stay
        </Button>
      </DialogActions>
    </AppDialog>
  );
};
