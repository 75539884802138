import { css } from "./stitches.config";

css.global({
  "*": {
    fontFamily: "$roboto",
    "&:focus": {
      outline: "none",
    },
  },
  body: {
    height: "100%",
    overflow: "hidden",
    boxSizing: "border-box",
    fontFamily: "$roboto",
},
});
