import React, { useState } from "react";
import "../Pages/Login";

export const PasswordField = ({
  handleChange,
  handleBlur,
  error = "",
  inputClass,
  inputStyle,
  value,
  name,
  id,
  placeholder,
}) => {
  const [showPwd, setShowPwd] = useState(false);

  return (
    <div className="login--input-container">
      <input
        type={`${showPwd ? "text" : "password"}`}
        className={`form-control form-control-user ${inputClass}`}
        id={id}
        name={name}
        placeholder={placeholder}
        onChange={handleChange}
        onBlur={handleBlur}
        style={inputStyle}
        value={value}
      />
      <div className="show-pwd" onClick={() => setShowPwd(!showPwd)}>
        {showPwd ? "hide" : "show"}
      </div>
    </div>
  );
};
