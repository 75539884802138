import React, { useState } from "react";
import { Route } from "react-router-dom";
import { useCurrentProject } from "../contexts/CurrentProjectContext";
import SelectProjectModal from "./SelectProjectModal";

function ProtectedRoute({ component: Component, ...restOfProps }) {
  const { project } = useCurrentProject();
  const [openProjectSelect, setShowProjectSelect] = useState(true);

  return (
    <Route
      {...restOfProps}
      render={(props) =>
        project ? (
          <Component {...props} />
        ) : (
          <SelectProjectModal
            showProjectSelect={openProjectSelect}
            setShowProjectSelect={setShowProjectSelect}
          />
        )
      }
    />
  );
}

export default ProtectedRoute;
