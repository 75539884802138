import * as Primitives from "@radix-ui/react-dropdown-menu";
import { styled, css } from "../stitches.config";
import Box from "./Box";
import { ReactComponent as ArrowDownIcon } from "../assets/icons/caret-down.svg";

const StyledTrigger = styled(Primitives.DropdownMenuTrigger, {
  bg: "white",
  outline: "none",
  cursor: "pointer",
  border: "none",
  fontSize: "16px",
  color: "$blue100",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

export const DropdownRoot = Primitives.Root;

export const DropdownTrigger = ({ children }) => {
  return (
    <StyledTrigger>
      {children}
      <Box css={{ marginLeft: 10 }}>
        <ArrowDownIcon style={{ width: 14 }} />
      </Box>
    </StyledTrigger>
  );
};

const showDropdownContent = css.keyframes({
  from: {
    transform: "scale(.5)",
    opacity: 0,
  },
  to: {
    transform: "scale(1)",
    opacity: 1,
  },
});

export const DropdownContent = styled(Primitives.DropdownMenuContent, {
  background: "white",
  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
  borderRadius: "3px",
  width: 175,
  animation: `${showDropdownContent} .15s ease`,
});

export const DropdownItem = styled(Primitives.Item, {
  padding: "8px 15px",
  color: "$blue100",
  fontSize: "16px",
  "&:focus": {
    background: "$blue100",
    color: "white",
  },
});
