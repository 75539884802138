
/*
  @sample usage
  combine(
    [4,5,6,7],
    [ {name: 'analog 1', id: '1',}, { name: 'analog   2', id: '2'} ],
    'analogId',
    value => value
  ) 
  -> 
  [{analogId: '1', 4}, {analogId: '2', value: 5 }]
*/

function combine(cells, columns, idkey, valueFormatter) {
    return columns.map((col, index) => {
      let value = cells[index];
      if (typeof valueFormatter === "function") {
        value = valueFormatter(value);
      }
      return {
        value: value,
        [idkey]: col.id,
      };
    });
  }
  
  export function prepareSetupPayload(state, isTemporaryData = false) {
    const {
      segmentsData,
      analogs,
      segments,
      categories,
      profiles,
      aux,
      categoriesData,
      total,
      projectInfo,
      parametersDTO,
      parametersTempDTO
    } = state;
  
    const formatValue = (value) => {
      // if istemporaryData is true we format value as strings
      // because temporary data are saved as strings
      return isTemporaryData ? String(value ?? "") : value;
    };
  
    const prepareCategoryData = () => {
      return categoriesData.map((row) => {
        const analogData = combine(
          row.analogData,
          analogs,
          "analogId",
          formatValue
        );
        const profileData = combine(
          row.profileData,
          profiles,
          "profileId",
          formatValue
        );
        const specialtyData = combine(
          row.specialtyData,
          aux.specialty,
          "specialtyId",
          formatValue
        );
        const avgTrxData = combine(row.avgTrxData, aux.avgTrx, "avgTrxId");
        const avgPatientsData = combine(
          row.avgPatientsData,
          aux.avgPatients,
          "avgPatientId",
          formatValue
        );
        const regionData = combine(row.regionData, aux.region, "regionId");
        const payerCoverageData = combine(
          row.payerCoverageData,
          aux.payerCoverage,
          "payerCoverageId",
          formatValue
        );
  
        return {
          categoryId: categories[row.categoryIndex].id,
          hcp: formatValue(row.hpc),
          marketData: [{ value: formatValue(row.market), marketId: "1" }],
          analogData,
          profileData,
          specialtyData,
          avgTrxData,
          avgPatientsData,
          regionData,
          payerCoverageData,
        };
      });
    };
  
    const prepareTotal = () => {
      const analogData = combine(
        total.analogData,
        analogs,
        "analogId",
        formatValue
      );
      const profileData = combine(
        total.profileData,
        profiles,
        "profileId",
        formatValue
      );
      const specialtyData = combine(
        total.specialtyData,
        aux.specialty,
        "specialtyId",
        formatValue
      );
      const avgTrxData = combine(total.avgTrxData, aux.avgTrx, "avgTrxId");
      const avgPatientsData = combine(
        total.avgPatientsData,
        aux.avgPatients,
        "avgPatientId",
        formatValue
      );
      const regionData = combine(
        total.regionData,
        aux.region,
        "regionId",
        formatValue
      );
      const payerCoverageData = combine(
        total.payerCoverageData,
        aux.payerCoverage,
        "payerCoverageId",
        formatValue
      );
      return {
        hcp: formatValue(total.hpc),
        marketData: [{value: formatValue(total.market), marketId: "1"}],
        analogData,
        profileData,
        specialtyData,
        avgTrxData,
        avgPatientsData,
        regionData,
        payerCoverageData,
      };
    };
  
    const prepareSegmentData = () => {
      return segmentsData.map((row) => {
        const analogData = combine(
          row.analogData,
          analogs,
          "analogId",
          formatValue
        );
        const profileData = combine(
          row.profileData,
          profiles,
          "profileId",
          formatValue
        );
        const specialtyData = combine(
          row.specialtyData,
          aux.specialty,
          "specialtyId",
          formatValue
        );
        const avgTrxData = combine(
          row.avgTrxData,
          aux.avgTrx,
          "avgTrxId",
          formatValue
        );
        const avgPatientsData = combine(
          row.avgPatientsData,
          aux.avgPatients,
          "avgPatientId",
          formatValue
        );
        const regionData = combine(
          row.regionData,
          aux.region,
          "regionId",
          formatValue
        );
        const payerCoverageData = combine(
          row.payerCoverageData,
          aux.payerCoverage,
          "payerCoverageId",
          formatValue
        );
        return {
          segmentId: segments[row.segmentIndex].id,
          categoryId: row.categoryId,
          hcp: formatValue(row.hpc),
          marketData: [{ marketId: "1", value: formatValue(row.market) }],
          profileData,
          analogData,
          specialtyData,
          avgTrxData,
          avgPatientsData,
          regionData,
          payerCoverageData,
        };
      });
    };
  
    const totalData = prepareTotal();
    const categoryData = prepareCategoryData();
    const segmentData = prepareSegmentData();
  
    let payload = {
      config: {
        segmentSize: formatValue(segments.length),
        analogSize: formatValue(analogs.length),
        categorySize: formatValue(categories.length),
      },
      categories,
      segments,
      segmentData,
      analogs: analogs,
      profiles: profiles,
      avgTrx: aux.avgTrx,
      avgPatients: aux.avgPatients,
      payerCoverage: aux.payerCoverage,
      specialty: aux.specialty,
      region: aux.region,
      categoryData,
      totalData,
      therapeuticId: projectInfo.therapeuticId,
      therapeuticName: projectInfo.therapeuticName,
      projectName: projectInfo.projectName,
      projectId: projectInfo.projectId,
    };

    if (parametersDTO) {
      payload.parametersDTO = parametersDTO;
    }

    if (parametersTempDTO) {
      payload.parametersTempDTO = parametersTempDTO;
    }

    return payload;
  }