import { ErrorBoundary } from "react-error-boundary";
import { useEffect, useState } from "react";

export function ErrorFallback({ error }) {
  const [waitSec, setwaitSec] = useState(15);

  useEffect(() => {
    if (waitSec > 0) {
      setTimeout(() => {
        setwaitSec((sec) => sec - 1);
      }, 1000);
    } else {
      window.location.assign("/analyst");
    }
  });

  return (
    <div style={{ padding: "20px" }}>
      <h1 style={{ fontSize: 24 }}>An unrecoverable error occured!</h1>
      {error && (
        <h2 style={{ fontSize: 18 }}>
          {error.name} - {error.message}
        </h2>
      )}
      <p>will auto reload in {waitSec} seconds</p>
    </div>
  );
}

export default function ErrorBoundry(props) {
  if (process.env.NODE_ENV === "production") {
    return (
      <ErrorBoundary
        {...props}
        FallbackComponent={ErrorFallback}
      ></ErrorBoundary>
    );
  }
  return props.children;
}
