import authClient from './httpClient/authClient';

//----------------
// services
//----------------
const authService = {
	async authenticateUser(payload) {
		const response = await authClient.post('/User/login', {
			...payload,
		});
		const { data } = response;
		return data;
	},
	async registerUser(payload) {
		let regData = null;
		let errData = null;

		await authClient
			.post('/User/register', {
				...payload,
			})
			.then((response) => {
				regData = response;
			})
			.catch(({ response }) => {
				errData = response;
			});
		return { regData, errData };
	},
	async addRole(payload) {
		return authClient.post('/Role', payload);
	},

	async updateRole(roleId, payload) {
		let { permissions, ...others } = payload;
		return Promise.all([
			authClient.put(`/Role`, { id: roleId, ...others }).catch(() => false),
			permissions.length > 0 &&
				authClient.post(`/Role/permissions`, {
					roleId,
					permissions,
				}),
		]);
	},

	async fetchRoles() {
		const response = await authClient.get(`/Role`);
		const { data } = response;
		return data;
	},
	async deleteRole(roleId) {
		const response = authClient.delete(`/Role/${roleId}`);
		return response;
	},

	async updatePassword(payload) {
		const response = authClient.post(`/User/changepassword`, payload);
		return response;
	},
	async fetchAuditLog(
		SearchValue = '',
		SortBy = 'desc',
		IsSortAscending = false,
		PageNumber = 1,
		MaxItem = 10
	) {
		const response = await authClient.get(
			`/Audit?SearchValue=${SearchValue}&SortBy=${SortBy}&IsSortAscending=${IsSortAscending}&PageNumber=${PageNumber}&MaxItem=${MaxItem}`
		);
		const { data } = response;
		return data;
	},
	async fetchUsers(
		SearchValue = '',
		SortBy = 'desc',
		IsSortAscending = false,
		PageNumber = 1,
		MaxItem = 10
	) {
		const response = await authClient.get(
			`/User/getusers?SearchValue=${SearchValue}&SortBy=${SortBy}&IsSortAscending=${IsSortAscending}&PageNumber=${PageNumber}&MaxItem=${MaxItem}`
		);
		const { data } = response;
		return data;
	},
	async unblockUser(payload) {
		await authClient.post(`/User/unlockuser`, payload);
	},

  async fetchPermissions() {
    const response = await authClient.get(`/Role/permissions`);
    const { data } = response;
    let set = {}
    for (let perm of data) {
      let {name } = perm;
      if (!set[name]) {
        set[name] = perm;
      }
    }
    return Object.values(set)
  },
  async fetchRolePermissions(roleId) {
    const { data } = await authClient.get(`/Role/${roleId}/permissions`);
    let permissions = [...data.permissionName];
    return permissions;
  },

  async activateToggle(payload) {
    await authClient.post(`/User/activate/toggle`, payload);
  },
};

export default authService;
