import client from "./httpClient/setupClient";
import authClient from "./httpClient/authClient";
import { prepareSetupPayload } from "../utils/setup/preparePayload";

//----------------
// services
//----------------

const setupService = {
  async save(state, overides={}) {
    let body = prepareSetupPayload(state, false)
    body = Object.assign(body, overides)
    const {
      data: { data },
    } = await client.post("SetUp", body); 
    return  data;
  },
  async update(projectId, state, overides) {
    let body = prepareSetupPayload(state, false)
    body = Object.assign(body, overides)
    await client.put(`SetUp/${projectId}`, body);
  },

  async deleteProject(projectId) {
    await client.delete(`/SetUp/${projectId}`);
  },

  async loadSetupData(projectId) {
    const {
      data: { data },
    } = await client.get(`SetUp/Basedata/${projectId}`);
    return data;
  },

  async loadTempSetupData(projectId) {
    const {
     data: { data }
    } = await client.get(`SetUp/basedatatemp/${projectId}`);
    return data;
  },

  async saveTempSetupData(state, overides={}) {
    let payload = prepareSetupPayload(state, true)
    // overides some fields in payload
    payload = Object.assign(payload, overides)
    return client.post("/SetUp/temp", payload).then(res => res.data.data)
  },

  async uploadFile(projectName, companyId, therapeauticId, file) {
    const formdata = new FormData();
    formdata.append("ProjectName", projectName);
    formdata.append("TherapeuticId", therapeauticId);
    formdata.append("CompanyId", companyId);
    formdata.append("Files", file);
    const {
      data: { data },
    } = await client.post("SetUp/upload", formdata, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    const { projectdto } = data;
    return { projectdto, basedataObj: data };
  },
  async fetchProjects(companyId = 1, completed) {
    let params =  {};
    if (completed === true) {
      params.completed = completed
    }
    const {
      data: { data },
    } = await client.get(`SetUp/projects/${companyId}`, {
      params,
    });
    return data;
  },
  async fetchCompanies() {
    const response = await authClient.get(`/Company`);
    const { data } = response;
    return data;
  },
  async importProjectBaseData(projectId) {
    const {
      data: { data },
    } = await client.get(`SetUp/basedata/import/${projectId}`);
    return data;
  },

  async checkProjectNameExist(projectName) {
    return client
      .get(`SetUp/isprojectexist/${projectName}`)
      .then(() => true) // exist
      .catch(() => false); // doesnt exist
  },

  async fetchTherapeuticAreas() {
    return client.get(`/SetUp/therapeutic`).then(({ data }) => data.data);
  },

  async addNewTherapeuticArea(name, companyId) {
    return client.post("/SetUp/therapeutic", {
      name,
      companyId,
    });
  },

};


export default setupService;
