import * as Yup from "yup";
import { useFormik } from "formik";
import authService from "../../services/auth";
import p4Logo from "../../assets/img/p4-logo.svg";
import { useHistory } from "react-router";
import { CircularProgress } from "@material-ui/core";
import { useEffect, useState } from "react";
import TokenService from "../../services/httpClient/tokenManager";
import { useContext } from "react";
import { AuthContext } from "../../AuthContextProvider";
import "./login.css";

const loginValidationSchema = Yup.object({
  email: Yup.string().email().required("username is required."),
  password: Yup.string().required("password is required."),
});

export default function Login() {
  const history = useHistory();
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [loginErr, setloginErr] = useState("");
  const [showPwd, setShowPwd] = useState(false);

  const authContextValue = useContext(AuthContext);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginValidationSchema,
    onSubmit: async (values) => {
      await handleLogin(values);
    },
  });

  useEffect(() => {
    setloginErr("");
  }, [formik.values]);

  const handleLogin = async (values) => {
    setIsAuthenticating(true);
    try {
      const user = await authService.authenticateUser(values);
      let adminsRole = ['admin', 'superadmin']
      user.isAdmin = adminsRole.includes(user.role)
      TokenService.setUser(user);
      sessionStorage.setItem("authUser", JSON.stringify(user));
      authContextValue.setUser(user)

      if (user.isAdmin) {
        history.push("/admin");
      } else {
        history.push("/analyst");
      }
    } catch (error) {
      if (error.response) {
        let {status,title} = error.response.data
        if (status === 401) {
            setloginErr('Incorrect email or password')
        } else {
          setloginErr(title)
        }
      }
      setIsAuthenticating(false);
    }
  };

  return (
    <div className="login--container">
      <div className="container login-body">
        <main role="main" className="w-100">
          <div className="container">
            <div className="row justify-content-center">
              <div className="login-card">
                <div className="login-card--left">
                  <div className="welcome-back-login">
                    <div className="login-logo">
                      <img src={p4Logo} alt="p4 logo" />
                    </div>
                    <div className="login-welcome">Welcome Back</div>
                    <div className="login-welcome--msg">
                      PROPENSITY4’s Promotion Optimizer offering is a robust and
                      flexible platform that accelerates analysis of your brand
                      promotional plan. Following recent industry trend of
                      increasing attention to spend management, and the rise in
                      available channels and opportunities for engagement, the
                      importance of considering promotion optimization is
                      increasing and will remain imperative to brand planning.
                    </div>
                  </div>
                </div>
                <div className="login-card--right">
                  <div className="p-5">
                    <h1 className="login--title">Log In</h1>
                    <form
                      id="LoginForm"
                      method="post"
                      className="login--form"
                      onSubmit={formik.handleSubmit}
                    >
                      {loginErr !== "" && (
                        <div className="login--error">
                          {loginErr ||
                            "Invalid Username and/or Password"}
                        </div>
                      )}
                      <div className="row" style={{ gap: "1.5rem" }}>
                        <div className="col-12">
                          <div className="login--input-container">
                            <input
                              type="email"
                              className="form-control form-control-user"
                              id="email"
                              name="email"
                              aria-describedby="emailHelp"
                              placeholder="Enter Email Address..."
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            {formik.errors.email && formik.touched.email && (
                              <div className="login--input-error">
                                {formik.errors.email}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="login--input-container">
                            <input
                              type={`${showPwd ? "text" : "password"}`}
                              className="form-control form-control-user"
                              id="password"
                              name="password"
                              placeholder="password"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            <div
                              className="show-pwd"
                              onClick={() => setShowPwd(!showPwd)}
                            >
                              {showPwd ? "hide" : "show"}
                            </div>
                            {formik.errors.password &&
                              formik.touched.password && (
                                <div className="login--input-error">
                                  {formik.errors.password}
                                </div>
                              )}
                          </div>
                          <button
                            disabled={isAuthenticating}
                            className="primary-link mr-2 mt-3"
                            type="button"
                            onClick={() => history.push("/password-reset")}
                          >
                            Forgot Password?
                          </button>
                        </div>
                        {/* <div>
                          <button className="primary-link">
                            Forgot password?
                          </button> 
                        </div> */}
                        <div
                          className="col-12 d-flex justify-content-end"
                          style={{ gap: "1rem" }}
                        >
                          {/* <button
                            disabled={isAuthenticating}
                            className="primary-link mr-2"
                            type="button"
                            onClick={() => history.push("/signup")}
                          >
                            Register Account
                          </button> */}

                          <button
                            type="submit"
                            id="LoginButton"
                            className="primary-action"
                            disabled={isAuthenticating}
                          >
                            {isAuthenticating ? (
                              <CircularProgress color="inherit" size="20px" />
                            ) : (
                              "Login"
                            )}
                          </button>
                        </div>
                      </div>

                      <input
                        name="__RequestVerificationToken"
                        type="hidden"
                        value="CfDJ8MxvxXLLVJFEp0UEvMVsKicR1vUYgUiilLb5a3XdnuUvc1fVdU8GY31uHsKFyHWaVPF5Zwr4xIsvC2k1-zG-3T-9QQ4_3_h45SGz3HTngLJWmlUQ8D5esapvPam5y4RPCh3qVU1rwk4KMyStCj0YxvE"
                      />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
